.logo-container {
  height: 120px;
  width: 170px;
  border-radius: 0px 0px 55px 0px;
  background: #fff;
  display: flex;
  align-items: center;
}
.logo-container img {
  height: 120px;
  width: 170px;
  object-fit: cover;
}
.nav-sections {
  width: 50%;
}
.nav-options {
  z-index: 1000;
  width: 100%;
  color: #fff;
  font-size: 22px;
  position: absolute;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  opacity: 1;
  background-color: #474787;
  animation: slideIn 0.3s forwards;
}

.nav-options li {
  margin-top: 20px;
}

@keyframes slideIn {
  0% {
    transform: translateX(-600px);
  }
  100% {
    transform: translateX(0);
  }
}
